import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, RouterModule } from "@angular/router";
import { setDispatcherDashboard } from "@services/index";
import { TopnavAppComponent } from "./topnav";
import { Const } from "@const/Const";
import { verifyWhileUsingDashboard } from "@services/auth.check-role";
import { MasterData } from "@services/master.data";
import { UIHelper } from "@services/UIHelper";
import { NgIf } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TrackTraceService } from '@app/track-trace/services/track-trace.service';

const CustomInputs = [
  NgIf,
  NzIconModule,
  RouterModule,
  TopnavAppComponent
]
@Component({
  selector: '[dispatcher-dashboard]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss'],
  providers: [TrackTraceService]
})

export class DispatcherDashboardComponent extends BaseComponent {

  public isLoadingMasterData;

  @ViewChild('topBar', {static: true}) topBar: TopnavAppComponent;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    setDispatcherDashboard(this);
  }

  ngOnInit() {
    if (!this.isLoggedIn) {
      let currentUrl = this.router.url; 
      return this.goLogin(currentUrl);
    }
    // Cần check lại do permission có thể bị thay đổi
    verifyWhileUsingDashboard(this.authUser, this.router);

    this.getMasterData();
    super.ngOnInit();
    if (this.router.url === Const.routeDashboard) {
      this.doInitialRouting();
    }
  }

  doInitialRouting() {
    let link = this.getInitialLink();
    if (link) {
      this.router.navigate([link]);
    }
  }

  getInitialLink() {
    let path = 'track-trace';
    return `/dashboard/${path}`;
  }
  
  onRouterActivate(compRef) {
  }

  onRouterDeactivate(compRef) {
  }

  getMasterData() {
    this.isLoadingMasterData = true;
    this.api.GET(Const.API_PUBLIC('masters')).subscribe(
      resp => { 
        MasterData.set(resp.data);
        this.isLoadingMasterData = 'OK';
      }, err => {
        UIHelper.showErr(err);
        this.isLoadingMasterData = 'NG';
      });
  }
}