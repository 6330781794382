<div class="top-bar">
  <div class="logo-box center-children">
    <img class="warp-logo" src="assets/img/warp_logo.png">
  </div>
<!-- 
  <div class="user-info">
    <div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" nzPlacement="bottomRight" style="cursor: pointer;">
      <div class="center-children">
        <div class="ava-box center-children" [ngClass]="{'online': isOnline}">
          <i nz-icon nzType="user" nzTheme="outline"></i>
        </div>
        <div class="user-name" [ngClass]="{'online': isOnline}">{{userName}}</div>
         <i class="fa fa-fw fa-angle-down"></i>
      </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <ng-container *ngFor="let item of navMenuItems">
          <li nz-menu-item (click)="onMenuItemClick(item)">
            <i *ngIf="item.nzIcon" nz-icon [nzType]="item.nzIcon" nzTheme="outline" style="margin-right: 10px;"></i>
            <span *ngIf="!item.link">{{item.label}}</span>
          </li>
        </ng-container>
      </ul>
    </nz-dropdown-menu>
  </div> -->
  <div class="shift-container" 
      nz-tooltip 
      [nzTooltipTitle]="isEndShift ? 'Start your shift' : 'End your shift'" 
      [ngClass]="{'active': isEndShift}" 
      (click)="onConfirmShift()">
    <span 
          nz-icon 
          nzType="poweroff" 
          nzTheme="outline"
    >
    </span>
  </div>
</div>