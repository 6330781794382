import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject } from 'rxjs';
import { AttachedFile, Job, Shipment, ShipmentItem, Stop, WorkTask, WorkTaskHistory } from '@wearewarp/types/data-model';
import { Task } from '@wearewarp/types/data-model';
import { FormDataUpdateTaskStatus } from '@wearewarp/ng-antd-2';
import { FormDataCreateTonuRoute } from '@wearewarp/ng-antd-2';
import { ApiService } from '@services/api.service';
import { environment } from '@env/environment';
import { UIHelper } from '@services/UIHelper';
import { Const } from '@const/Const';
import { JobUpdateFormId } from '@wearewarp/types-server-admin/form-data/dispatch';
import { Log } from '@services/log';

/**
 * Service lấy task từ work queue
 * phục vụ những task liên quan đến track & trace
 */
@Injectable({
  providedIn: 'root'
})
export class TrackTraceService {
  private taskInfo = new BehaviorSubject<WorkTask | null>(null);
  private loadInfo = new BehaviorSubject<Job | null>(null);
  private logHistory = new BehaviorSubject<WorkTaskHistory[]>([]);
  public fetchingTaskInfo = new BehaviorSubject<boolean>(false);
  public fetchingLoadInfo = new BehaviorSubject<boolean>(false);
  public isSpecificTask = new BehaviorSubject<boolean>(false);

  private jobId: string;
  private resetNotesSubject = new Subject<void>();
  public stopWorking$ = new BehaviorSubject<boolean>(this.getBreakingState());

  public isUpdatingTaskStatus = new BehaviorSubject<boolean>(false);
  private podUpdates = new BehaviorSubject<{taskIds: string[], pods: any[]} | null>(null);

  private podsMap: Map<string, AttachedFile> = new Map();


  constructor(private api: ApiService) {}

  getWorkTask(): Observable<WorkTask | null> {
    return this.taskInfo.asObservable();
  }

  getLogHistory_ForRoute(refresh: boolean = false): Observable<WorkTaskHistory[]> {
    return this.logHistory.asObservable();
  }

  getLoadInfo(): Observable<Job | null> {
    return this.loadInfo.asObservable();
  }

  // reload lại data của task hiện tại
  refreshThisTask() {
    const isSpecificTask = this.isSpecificTask.value;
    const taskId = this.taskInfo?.value?.id;
    if (!taskId) return;
    this.fetchNewTask({isSpecificTask, taskId});
  }

  setSpecificTask(taskId: string) {
    if (this.taskInfo?.value?.id === taskId) {
      return;
    }
    Log.d('Get specific task', taskId);
    this.fetchNewTask({isSpecificTask: true, taskId});
  }

  getTaskFocusOnRoute(jobId: string) {
    this.fetchNewTask({isSpecificTask: true, focusOnRouteId: jobId})
  }

  fetchNewTask(ops: {
    isSpecificTask: boolean, 
    taskId?: string, 
    focusOnRouteId?: string
    } = {isSpecificTask: false, taskId: null, focusOnRouteId: null}
  ) {
    this.fetchingTaskInfo.next(true);
    let url = `${environment.backendWorkQueueUrl}/${Const.API_FETCH_TASK}`
    if (ops.taskId) {
      url = `${environment.backendWorkQueueUrl}/${Const.API_GET_SPECIFIC_TASK(ops.taskId)}`;
    }
    if (ops.focusOnRouteId) {
      url = `${environment.backendWorkQueueUrl}/${Const.API_TRACKTRACE_GET_TASK_FOCUS_ON_ROUTE(ops.focusOnRouteId)}`;
    }
    this.isSpecificTask.next(ops.isSpecificTask);
    this.api.GET(url).subscribe({
      next: (resp) => {
        console.log('work task', resp.data);
        if (resp.data) {
          this.taskInfo.next(resp.data as unknown as WorkTask);
          this.onGetWorkTaskDone(resp.data);
        } else {
          this.handleEmptyTask();
        }
        this.fetchingTaskInfo.next(false);
      },
      error: (err) => {
        this.fetchingTaskInfo.next(false);
        UIHelper.showErr(err);
      }
    });
  }

  handleEmptyTask() {
    this.taskInfo.next(null);
    this.loadInfo.next(null);
    this.logHistory.next([]);
  }

  onGetWorkTaskDone(task: WorkTask) {
    if (task?.object?.entity === Const.WorkTaskObjectEntity.job && task?.object?.entityId) {
      this.jobId = task.object.entityId;
      this.refreshLoadInfo();
    }
    else {
      UIHelper.showErr('Invalid track & trace task');
    }
  }

  fetchLoadInfo() {
    this.fetchingLoadInfo.next(true);
    const url = `${environment.backendWorkQueueUrl}/v1/jobs/${this.jobId}`;
    this.api.GET(url).subscribe(resp => {
      console.log('job', resp.data);
      if (resp.data) {
        this.loadInfo.next(resp.data as unknown as Job);
        this.fetchLogHistory_ForRoute();
      } else {
        this.loadInfo.next(null);
        UIHelper.showErr(`Load info is null`)
      }
      this.fetchingLoadInfo.next(false);
    });
  }

  refreshLoadInfo() {
    this.loadInfo.next(null);
    this.fetchLoadInfo();
  }

  updateLoadInfo(info: Job) {
    this.loadInfo.next(info);
  }

  getStopInfo(stopId: string): Observable<Stop> {
    return this.loadInfo.asObservable().pipe(
      map(loadInfo => loadInfo?.stops.find(stop => stop.id === stopId))
    );
  }

  getShipments(shipmentIds: string[]): Observable<Shipment[]> {
    return this.api.POST(`${environment.backendWorkQueueUrl}/v1/shipments`, {
      shipmentIds
    }).pipe(
      map(response => response?.data?.list_data as unknown as Shipment[])
    );
  }

  getShipmentItems(shipmentIds: string[]): Observable<ShipmentItem[]> {
    return this.api.POST(`${environment.backendWorkQueueUrl}/v1/shipments/shipment_items`, {
      shipmentIds
    }).pipe(
      map(response => response?.data?.list_data as unknown as ShipmentItem[])
    );
  }

  getTasks(taskIds: string[]): Observable<Task[]> {
    const params = { taskIds }
    const url = `${environment.backendWorkQueueUrl}/v1/job_tasks`;
    return this.api.POST(url, params).pipe(
      map(response => response?.data?.list_data as unknown as Task[])
    );
  }

  getSpecificTask(taskId: string): Observable<Task> {
    const url = `${environment.backendUrl}/v1/tasks/${taskId}`;
    return this.api.GET(url).pipe(
      map(response => response?.data as unknown as Task)
    );
  }

  updateTaskStatus(taskIds: string[], data: FormDataUpdateTaskStatus) {
    if (!taskIds.length) return;
    const params = {
      action: "updateStatus",
      data: taskIds.map(taskId => {
        return { id: taskId, data }
      })
    }
    this.isUpdatingTaskStatus.next(true);
    const url = `${environment.backendUrl}/v2/tasks/batchUpdate`;
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        this.isUpdatingTaskStatus.next(false);
        UIHelper.showSuccess('Update Task Status, Task status updated successfully');
        this.refreshLoadInfo();
      },
      error: (err) => {
        this.isUpdatingTaskStatus.next(false);
        UIHelper.showErr(err);
      }
    })
  }

  updateTimeWindows(formId: JobUpdateFormId, params: any) {
    const url = `${environment.backendUrl}/v2/jobs/${this.jobId}/${formId}`;
    return this.api.PUT(url, params);
  }

  createTonuRoute(data: FormDataCreateTonuRoute) {
    // TODO: create tonu route on server
    return of({});
  }

  getCurrentStop(): Stop | null {
    if (!this.loadInfo?.value || !this.taskInfo?.value) {
      return null;
    }
    //nếu có stop status là created, enroute, arrived thì return luôn
    let stop = this.loadInfo?.value?.stops?.find(stop => [null, Const.StopStatus.created, Const.StopStatus.enroute, Const.StopStatus.arrived].includes(stop.status));
    if (stop) {
      return stop;
    }
    //nếu không thì return stop completed cuối cùng
    const completedStops = this.loadInfo?.value?.stops?.filter(stop => stop.status === Const.StopStatus.succeeded);
    if (completedStops.length > 0) {
      return completedStops[completedStops.length - 1];
    }
    return this.loadInfo?.value?.stops?.[0] || null;
  }

  findStopIndex(stopId: any): number {
    if (!stopId) return 0;
    return this.loadInfo?.value?.stops?.findIndex(stop => stop.id === stopId) || 0;
  }

  fetchLogHistory_ForRoute() {
    const entity = 'jobs';
    const entityId = this.jobId;
    const url = `${environment.backendWorkQueueUrl}/v1/history/${entity}/${entityId}/`;
    this.api.GET(url).subscribe(response => {
      this.logHistory.next(response.data.list_data as unknown as WorkTaskHistory[]);
    });
  }

  onUpdateTaskAnswerSuccess() {
    this.fetchLogHistory_ForRoute();
  }

   // User end shift
    stopWorking() {
    this.saveBreakingState(true);
    this.stopWorking$.next(true);
    const url = `${environment.backendWorkQueueUrl}/v1/shift-management/user-end-shift`;
    this.api.POST(url, {}).subscribe(response => {
      this.stopWorking$.next(true);
      this.handleEmptyTask();
    });
  }

  continueWorking() {
    this.saveBreakingState(false);
    this.stopWorking$.next(false);
    this.fetchNewTask();
  }

  resetNotes() {
    this.resetNotesSubject.next();
  }

  getResetNotes(): Observable<void> {
    return this.resetNotesSubject.asObservable();
  }

  // POD
  public getPODById(podId: string) {
    return this.podsMap.get(podId);
  }

  getPodUpdates(): Observable<{taskIds: string[], pods: any[]} | null> {
    return this.podUpdates.asObservable();
  }

  refreshPodData(taskIds: string[]): Observable<Task[]> {
    if (!taskIds || taskIds.length === 0) {
      return of([]);
    }
    const params = { taskIds }
    const url = `${environment.backendWorkQueueUrl}/v1/job_tasks`;

    return this.api.POST(url, params).pipe(
      map(response => {
        const tasks = response?.data?.list_data as unknown as Task[];
        this.podUpdates.next({taskIds, pods: tasks});
        return tasks;
      })
    );
  }

  updateTasksInLoadInfo(tasks: Task[]) {
    const currentLoadInfo = this.loadInfo.getValue();
    if (!currentLoadInfo) return;

    const updatedLoadInfo = {...currentLoadInfo};

    if (updatedLoadInfo.tasks) {
      tasks.forEach(newTask => {
        const index = updatedLoadInfo.tasks.findIndex(t => t.id === newTask.id);
        if (index !== -1) {
          updatedLoadInfo.tasks[index] = newTask;
        }
      });
    }

    this.loadInfo.next(updatedLoadInfo);
  }
  
  // Đảm bảo khi reload trang thì trạng thái vẫn giữ nguyên
  private saveBreakingState(isBreaking: boolean): void {
    localStorage.setItem('userBreakingState', isBreaking.toString());
  }

  private getBreakingState(): boolean {
    const state = localStorage.getItem('userBreakingState');
    return state === 'true';
  }

  unassignMyTask(taskId: string) {
    const url = `${environment.backendWorkQueueUrl}/v1/w/d/track-trace/unassign-my-task/${taskId}`;
    return this.api.POST(url, {});
  }
}
