import { Routes } from "@angular/router";
import { DispatcherDashboardComponent } from "./dashboard";
import { PageError } from "@app/page-error/page-error";

export const DASHBOARD_ROUTES_V2: Routes = [
  {
    path: '', component: DispatcherDashboardComponent, data: { title: 'Dashboard - WARP Work' },
    children: [
      { path: 'track-trace', loadChildren: () => import('../track-trace/track-trace.routing').then(m => m.TRACK_TRACE_ROUTES)},
      { path: '**', component: PageError }
    ]
  }
]