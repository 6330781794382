<div class="app-content">
  <div style="width: 100%; overflow-x: auto; background-color: #F0F2F5; position: relative;">
    <app-topnav #topBar></app-topnav>
    <div #mainContainer class="content">
      <div *ngIf="isLoadingMasterData == true" class="nodata">
        <!-- <i nz-icon nzType="loading" nzTheme="outline"></i> -->
      </div>
      <div *ngIf="isLoadingMasterData == 'NG'" class="nodata" style="flex-direction: column;">
        <span style="font-size: 16px; color: #969696;">Ops something went wrong, please try again later.</span>
        <a [href]="currentUrl" style="margin-top: 20px; font-weight: bold; font-size: 16px;">
          <span class="fas fa-sync" style="margin-right: 10px;"></span>
          Reload
        </a>
      </div>
      <router-outlet *ngIf="isLoadingMasterData == 'OK'" (activate)="onRouterActivate($event)" (deactivate)="onRouterDeactivate($event)"></router-outlet>
    </div>
  </div>
</div>